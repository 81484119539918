import { PropsWithChildren } from 'react';

import { Box, Typography } from '@mui/material';

import ComingSoon from './ComingSoon';

export interface IActionCardInfo {
  title?: string;
  description: string;
  text?: string;
  image: JSX.Element;
  isStaking?: boolean;
  isLong?: boolean;
  comingSoon?: boolean;
  isGenerateAffiliateLink?: boolean;
}

export default function ActionCard({
  title,
  description,
  text,
  image,
  isStaking = false,
  isLong = false,
  comingSoon = false,
  children,
  isGenerateAffiliateLink = false,
}: IActionCardInfo & PropsWithChildren) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 6,
        justifyContent: 'space-between',
        borderRadius: 4,
        border: '1px solid #262829',
        height: isGenerateAffiliateLink ? 'fit-content' : '100%',
        background: isStaking
          ? '#182920'
          : 'linear-gradient(147.18deg, rgba(56, 255, 147, 0.04) 7.31%, rgba(34, 153, 88, 0) 127.25%)',
        position: 'relative',
        width: { md: isStaking ? '590px' : '100%' },
      }}
    >
      <Box
        sx={{
          top: 0,
          right: 0,
          position: 'absolute',
          borderTopRightRadius: 10,
          overflow: 'hidden',
        }}
      >
        {image}
      </Box>

      {title && (
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography
            sx={{
              zIndex: 10,
              color: '#CCD0D4',
              fontSize: { xs: '14px', md: '16px' },
              fontWeight: 500,
              textTransform: 'uppercase',
              width: 'auto',
            }}
          >
            {title}
          </Typography>
          {comingSoon && <ComingSoon />}
        </Box>
      )}

      <Box display="flex" flexDirection="column" pt={3} gap={6}>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: { xs: '20px', md: '24px' },
            color: '#F0F5FA',
            width: { xs: '262px', md: isLong ? '486px' : '237px' },
          }}
        >
          {description}
        </Typography>

        {text && (
          <Typography
            sx={{
              fontWeight: 300,
              fontSize: { xs: '12px', md: '14px' },
              color: '#ACAFB3',
              width: { xs: 'auto', md: isLong ? '486px' : '237px' },
              whiteSpace: 'pre-wrap',
            }}
            width="237px"
          >
            {text}
          </Typography>
        )}

        {children}
      </Box>
    </Box>
  );
}
